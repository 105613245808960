import { IComplexityRes,IComplexityTypes } from "./types";

export const ActionTypes = {
  COMPLEXITY_LIST: "COMPLEXITY_LIST",
  ADD_COMPLEXITY: "ADD_COMPLEXITY",
  UPDATE_COMPLEXITY: "UPDATE_COMPLEXITY",
  DELETE_COMPLEXITY: "DELETE_COMPLEXITY",
};

export const setComplexityList = (payload: IComplexityRes) => ({
  type: ActionTypes.COMPLEXITY_LIST,
  payload,
});

export const addComplexity = (payload: IComplexityTypes) => ({
  type: ActionTypes.ADD_COMPLEXITY,
  payload,
});

export const updateComplexity = (payload: IComplexityTypes) => ({
  type: ActionTypes.UPDATE_COMPLEXITY,
  payload,
});

export const deleteComplexity = (payload: IComplexityTypes) => ({
  type: ActionTypes.DELETE_COMPLEXITY,
  payload,
});