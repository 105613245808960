import { IStockTransferList, IInventoryTransferList, IStockTransferForm, IPickUpData } from "./types";

export const ActionTypes = {
  SET_STORE_STOCK_TRANSFER_LIST: "SET_STORE_STOCK_TRANSFER_LIST",
  DELETE_STOCK_TRANSFER_LIST:"DELETE_STOCK_TRANSFER_LIST",
  SET_STORE_INVENTORY_TRANSFER_LIST:"SET_STORE_INVENTORY_TRANSFER_LIST",
  SET_STORE_STOCK_TRANSFER_DETAILS:"SET_STORE_STOCK_TRANSFER_DETAILS",
  SET_STORE_STOCK_TOGGLE_LIST:"SET_STORE_STOCK_TOGGLE_LIST",
  SET_UPDATE_STOCK_TOGGLE_LIST:"SET_UPDATE_STOCK_TOGGLE_LIST",
  SET_STORE_STOCK_TRANSFER_PARENT:"SET_STORE_STOCK_TRANSFER_PARENT",
  SET_PICKUP_NO:"SET_PICKUP_NO",

};

export const setStoreStockTransferList = (payload: IStockTransferList) => ({
  type: ActionTypes.SET_STORE_STOCK_TRANSFER_LIST,
  payload,
});
export const setPickupNo = (payload: IPickUpData) => ({
  type: ActionTypes.SET_PICKUP_NO,
  payload,
});
export const deleteStockTransferList = (payload: string) => ({
  type: ActionTypes.DELETE_STOCK_TRANSFER_LIST,
  payload,
});
export const setStoreStockTransferDetails = (payload: IStockTransferList) => ({
  type: ActionTypes.SET_STORE_STOCK_TRANSFER_DETAILS,
  payload,
});
export const setStoreStockTransferParent = (payload: IStockTransferForm) => ({
  type: ActionTypes.SET_STORE_STOCK_TRANSFER_PARENT,
  payload,
});
export const setStoreStockToggleList = (payload: IStockTransferList) => ({
  type: ActionTypes.SET_STORE_STOCK_TOGGLE_LIST,
  payload,
});
export const setUpdateStockToggleList = (payload: IStockTransferForm) => ({
  type: ActionTypes.SET_UPDATE_STOCK_TOGGLE_LIST,
  payload,
});

export const setStoreInventoryTransferList = (payload: IInventoryTransferList) => ({
  type: ActionTypes.SET_STORE_INVENTORY_TRANSFER_LIST,
  payload,
});
