
import { ActionTypes } from './action';
import { IStockTransferType } from './types';


const initialState: IStockTransferType = {
  stockTransferList:{
    items: [],
    totalCount: 0,
    optionList: [],
    product_list: [],
  },
  inventoryTransferList:{
    items: [],
    totalCount: 0,
    optionList: [],
  },
  stockTransferDetails:null,
  stockToggleList:null,
  parentStock:null,
  pickup_number:null,
};

const stockTransferReducers = (state: IStockTransferType = initialState, action): IStockTransferType => {
  switch (action?.type) {
    case ActionTypes.SET_STORE_STOCK_TRANSFER_LIST:{
      const optionsList = action?.payload?.items?.map((item, index)=>({
        ...item,
        label: item?.name,
        value: item?.id
      }));
      return {
        ...state,
        stockTransferList:{
          ...action.payload,
          optionList: optionsList
        }
      };
    }
    case ActionTypes.SET_PICKUP_NO:{
      return {
       ...state,
        pickup_number: action?.payload
      }
    }
    case ActionTypes.DELETE_STOCK_TRANSFER_LIST:{
      const updatedList = state?.stockTransferList?.items.filter(
        (item) => item.id !== action.payload
      );     
      console.log("updatedList::",action.payload);

      const totalCount = state?.stockTransferList?.totalCount - 1;
      return {
        ...state,
        stockTransferList: {
          items: updatedList,
          product_list:state?.stockTransferList?.product_list,
          optionList:[],
          totalCount: totalCount,
        },
      }
    }
    case ActionTypes.SET_STORE_INVENTORY_TRANSFER_LIST:{
      const optionsList = action?.payload?.items?.map((item, index)=>({
        ...item,
        label: item?.name,
        value: item?.id
      }));
      return {
        ...state,
        inventoryTransferList:{
          ...action.payload,
          optionList: optionsList
        }
      };
    }
    case ActionTypes.SET_STORE_STOCK_TRANSFER_DETAILS:{
      return{
        ...state,
        stockTransferDetails: action?.payload?action?.payload:initialState?.stockTransferDetails
      }
    }
    case ActionTypes.SET_UPDATE_STOCK_TOGGLE_LIST:{
      const updatedList = state?.stockToggleList?.items?.map(
        (item) => item.id === action.payload?.items?.id ? action?.payload?.items:item
      );
      const updatedProductList = state?.stockToggleList?.product_list?.map(
        (item) => item.id === action.payload?.product_list[0]?.id ? action?.payload?.product_list[0]:item
      ); 
  
        
      return {
        ...state,
        stockToggleList: {
          items: updatedList,
          product_list:updatedProductList,
          optionList:[],
          totalCount: state?.stockToggleList?.totalCount,
        },}}

        
    case ActionTypes.SET_STORE_STOCK_TRANSFER_PARENT:{
      return{
        ...state,
        parentStock: action?.payload?action?.payload:initialState?.parentStock
      }
    }
    case ActionTypes.SET_STORE_STOCK_TOGGLE_LIST:{

      return{
        ...state,
        stockToggleList: action?.payload?action?.payload:initialState?.stockToggleList
      }
    }
    default:
      return state;
  }
};

export default stockTransferReducers;
