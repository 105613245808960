import { IShopOrdersRes } from "../shop";
import { AddIProjectType, drawingVersionTypes, IPackageDrawingList, IProjectListType, IProjectRes, ProjectListTypes, } from "./types";

export const ActionTypes = {
  SET_STORE_PROJECTS_LIST: "SET_STORE_PROJECTS_LIST",
  SET_STORE_PROJECT_DETAILS: "SET_STORE_PROJECT_DETAILS",
  SET_ADD_PROJECT_DETAILS: "SET_ADD_PROJECT_DETAILS",
  SET_DELETE_PROJECT_DETAILS: "SET_DELETE_PROJECT_DETAILS",
  SET_UPDATE_PROJECT_DETAILS: "SET_UPDATE_PROJECT_DETAILS",
  SET_STORE_FABRICATION_PROJECT_DETAILS: "SET_STORE_FABRICATION_PROJECT_DETAILS",
  //for package details
  SET_PACKAGE_LIST: "SET_PACKAGE_LIST",
  SET_ADD_PACKAGE_DETAILS:"SET_ADD_PACKAGE_DETAILS",

  //for assign project
  SET_ASSIGN_PROJECT_DETAILS: "SET_ASSIGN_PROJECT_DETAILS",

  //for drawing 
  SET_STORE_PACKAGE_DRAWING_LIST:"SET_STORE_PACKAGE_DRAWING_LIST",
  SET_DELETE_PACKAGE_DRAWING:"SET_DELETE_PACKAGE_DRAWING",

  //for listlog
  SET_LIST_LOG_LIST:"SET_LIST_LOG_LIST",
  SET_ADD_LIST_LOG:"SET_ADD_LIST_LOG",
  SET_DELETE_LIST_LOG:"SET_DELETE_LIST_LOG",

  //for revision history
  SET_REVISION_HISTORY_LIST:"SET_REVISION_HISTORY_LIST",
  SET_ADD_REVISION_HISTORY:"SET_ADD_REVISION_HISTORY",
  SET_DELETE_REVISION_HISTORY:"SET_DELETE_REVISION_HISTORY",

  //for drawing version
  SET_DRAWING_VERSION_LIST:"SET_DRAWING_VERSION_LIST",
  SET_ADD_DRAWING_VERSION:"SET_ADD_DRAWING_VERSION",
  SET_DELETE_DRAWING_VERSION:"SET_DELETE_DRAWING_VERSION",
  SET_STORE_PROJECT_SHOP_SUPPLY:"SET_STORE_PROJECT_SHOP_SUPPLY",

};

export const setStoreProjectsList = (payload: IProjectListType) => ({
  type: ActionTypes.SET_STORE_PROJECTS_LIST,
  payload,
});
export const setUpdateProjectsList = (payload: ProjectListTypes) => ({
  type: ActionTypes.SET_UPDATE_PROJECT_DETAILS,
  payload,
});
export const setStoreProjectsDetails = (payload: IProjectRes) => ({
  type: ActionTypes.SET_STORE_PROJECT_DETAILS,
  payload,
});

export const setAddProjectsDetails = (payload: AddIProjectType) => ({
  type: ActionTypes.SET_ADD_PROJECT_DETAILS,
  payload,
});
export const setDeleteProjectDetails = (payload: AddIProjectType) => ({
  type: ActionTypes.SET_DELETE_PROJECT_DETAILS,
  payload,
});
export const setStoreFabricationProjectsDetails = (payload: IProjectRes) => ({
  type: ActionTypes.SET_STORE_FABRICATION_PROJECT_DETAILS,
  payload,
});
//for package details
export const setStorePackagesList = (payload: IProjectRes) => ({
  type: ActionTypes.SET_PACKAGE_LIST,
  payload,
});
export const setAddPackagesDetails = (payload: IProjectRes) => ({
  type: ActionTypes.SET_ADD_PACKAGE_DETAILS,
  payload,
});

export const setAssignProjectDetails = (payload: ProjectListTypes) => ({
  type: ActionTypes.SET_ASSIGN_PROJECT_DETAILS,
  payload,
});

export const setStorePackageDrawingList = (payload: IPackageDrawingList) => ({
  type: ActionTypes.SET_STORE_PACKAGE_DRAWING_LIST,
  payload,
});
export const setDeletePackageDrawing = (payload: IPackageDrawingList) => ({
  type: ActionTypes.SET_DELETE_PACKAGE_DRAWING,
  payload,
});

//for listlog
export const setAddListlogDetails = (payload: IProjectRes) => ({
  type: ActionTypes.SET_ADD_LIST_LOG,
  payload,
});
export const setStoreListLogList = (payload: IProjectRes) => ({
  type: ActionTypes.SET_LIST_LOG_LIST,
  payload,
});
export const setDeleteListLog = (payload: AddIProjectType) => ({
  type: ActionTypes.SET_DELETE_LIST_LOG,
  payload,
});
//for revision history
export const setAddRevisionHistoryDetails = (payload: IProjectRes) => ({
  type: ActionTypes.SET_ADD_REVISION_HISTORY,
  payload,
});
export const setStoreRevisionHistoryList = (payload: IProjectRes) => ({
  type: ActionTypes.SET_REVISION_HISTORY_LIST,
  payload,
});
export const setDeleteRevisionHistory = (payload: ProjectListTypes) => ({
  type: ActionTypes.SET_DELETE_REVISION_HISTORY,
  payload,
});
//for drawing version
export const setAddDrawingVersionDetails = (payload: IProjectRes) => ({
  type: ActionTypes.SET_ADD_DRAWING_VERSION,
  payload,
});
export const setStoreDrawingVersionList = (payload: IProjectRes) => ({
  type: ActionTypes.SET_DRAWING_VERSION_LIST,
  payload,
});
export const setDeleteDrawingVersion = (payload: drawingVersionTypes) => ({
  type: ActionTypes.SET_DELETE_DRAWING_VERSION,
  payload,
});
export const setStoreProjectShopSupply = (payload: IShopOrdersRes) => ({
  type: ActionTypes.SET_STORE_PROJECT_SHOP_SUPPLY,
  payload,
});