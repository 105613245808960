/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable eqeqeq */
import {
  backOfficeProjectServicePermission,
  chatPermission,
  customerServicePermission,
  dashboardPermission,
  editInventoryPermission,
  fabricationServicePermission,
  inquiryServicePermission,
  inventoryServicePermission,
  logisticServicePermission,
  projectManagementPermission,
  projectServicePermission,
  proposalServicePermission,
  purchaseOrderPermission,
  saleOrderPermission,
  shopSupplyPermission,
  stockServicePermission,
  stockTransferServicePermission,
  submissionPermission,
  supplierServicePermission,
  webMailPermission,
} from "./rolesPermission";

/* eslint-disable no-useless-escape */
export const App_url = {
  link: {
    INITIAL_URL: "/",
    CHANGE_PASSWORD: "/change-password",
    DASHBOARD_URL: "/dashboard",
    CUSTOMER_URL: "/customers",
    SUPPLIER_URL: "/supplier",
    ADD_CUSTOMER_URL: "/customers/add",
    ADD_SUPPLIER_URL: "/supplier/add",
    EDIT_SUPPLIER_URL: "/supplier/:id",
    PROJECT_URL: "/projects",
    PROJECT_HISTORY: "/projects/history",
    ADD_PROJECT_URL: "/projects/project-details",
    ASSIGN_PROJECT_URL: "/projects/assign-projects",
    EDIT_PROJECT_URL: "/projects/project-details/:id",
    EDIT_PROJECT_ESCALATION_URL: "/projects/price-details",
    ADD_PRICE_URL: "/projects/price-details",
    ADD_ESCALATION_URL: "/projects/escalation-details",
    PROJECT_MANAGEMENT_URL:"/project-management",
    CHAT_URL:"/chat",
    DRAWING_TRACKER_URL:"/project-management/drawing-tracker/:id",
    LIST_LOG_URL:"/project-management/list-log/:id",
    CHANGE_ORDER_URL:"/project-management/change-order/:id",
    PROJECT_DISCUSSION_URL:"/project-management/project-discussion/:id",
    READY_FOR_BILLING_URL:"/project-management/ready-for-billing/:id",
    SUBMISSION_URL:"/submission",
    NEW_DRAWING_URL:"/submission/new-drawing/:id",
    REVISION_URL:"/submission/revision/:id",
    LISTING_URL:"/submission/listing/:id",
    WEBMAIL_URL:"/web-mail",
    MAIL_DESCRIPTION_URL:"/web-mail/mail-description",
    PROPOSAL_URL: "/proposal",
    ADD_PROPOSAL_URL: "/proposal/proposal-details",
    ADD_PROPOSAL_AGGREEMENT_URL: "/proposal/agreement",
    EDIT_PROPOSAL_URL: "/proposal/proposal-details",
    PREVIEW_SEND_PROPOSAL_URL: "/proposal/preview-and-send",
    DISTRIBUTION_URL: "/distribution",
    FABRICATION_URL: "/fabrication-supply",
    FABRICATION_ORDERS_URL: "/fabrication-supply/fabrication-orders",
    SHOP_SUPPLY_URL:"/shop-supply",
    SHOP_SUPPLY_ORDERS_URL:"/shop-supply/shop-orders",
    CREATE_SHOP_SUPPLY_URL:"/shop-supply/add",
    STOCK_SUPPLY_URL: "/stock-supply",
    ADD_STOCK_ORDER_URL: "/stock-supply/orders/add",
    STOCK_TRANSFER_URL: "/stock-transfer",
    ADD_STOCK_TRANSFER: "/stock-transfer/add",
    ADD_CHILD_STOCK_TRANSFER: "/stock-transfer",
    LOGISTIC_URL: "/logistics",
    ADD_INVENTORY_URL: "/inventory/add",
    CREATE_FABRICATION_ORDER_URL: "/fabrication-supply/fabrication-orders/add",
    HISTORY_INVENTORY_URL: "/inventory",
    INVENTORY_URL: "/inventory",
    EDIT_INVENTORY_URL: "/edit-inventory",  
    SIGNIN_URL: "/signin",
    FORGET_PASSWORD_URL: "/forget-password",
    INTERNAL_USER_URL: "/internal-users",
    ADD_INTERNAL_USER_URL: "/internal-users/add",
    EDIT_INTERNAL_USER_URL: "/internal-users/:id",
    EXTERNAL_USER_URL: "/external-users",
    ADD_EXTERNAL_USER_URL: "/external-users/add",
    EDIT_EXTERNAL_USER_URL: "/external-users/:id",
    ROLES_AND_PERMISSION: "/roles-and-permissions",
    ADD_ROLES: "/roles-and-permissions/add",
    EDIT_ROLES: "/roles-and-permissions/:id",
    RESET_PASSWORD_URL: "/reset-password",
    ALL_MASTER: "/all-masters",
    EmailSetup: "/api-credentials/email",
    QuickBook: "/api-credentials/quick-book",
    Padlet: "/api-credentials/padlet",
    SENDER_EMAIL_SETUP: "/sender-email-setup",
    TEMPLATE_EMAIL_SETUP: "/sender-email-setup/email",
    APICredentials: "/api-credentials",
    TYPE_OF_PROJECT_URL: "/all-masters/type-of-project",
    SCOPE_OF_PROJECT_URL: "/all-masters/scope-of-project",
    DELIVERY_BY: "/all-masters/delivery-by",
    PRODUCTS_URL: "/all-masters/products",
    SUPPLIER_TYPE_URL: "/all-masters/supplier-type",
    LOCALITY: "/all-masters/locality",
    SIZE_SPECIFICATION: "/all-masters/size-specification",
    SCOPE_OF_WORK_URL: "/all-masters/scope-of-work",
    GRADE_URL: "/all-masters/grade",
    COATING_URL: "/all-masters/coating",
    COMPLEXITY_URL: "/all-masters/complexity",
    UNIT: "/all-masters/unit",
    PRODUCT_LENGTH_URL: "/all-masters/product-length",
    STATUS_OF_DRAWING_URL: "/all-masters/status-of-drawing",
    DISTRIBUTION:"/distribution",
    REQUEST_FOR_QUOTATION_URL: "/distribution/request-for-quotation",
    SALE_ORDER_URL: "/distribution/sale-order",
    PURCHASE_ORDER_URL: "/distribution/purchase-order",
    DELIVERED_URL: "/distribution/delivered",
    ADD_SALE_ORDER_URL: "/distribution/sale-order/add",
    ADD_INQUIRY_URL: "/distribution/request-for-quotation/add",
    EDIT_INQUIRY_URL: "/distribution/request-for-quotation/:id",
    CONVERT_TO_SALES_ORDER: "/distribution/convert-to-sales-order",
    DISTRIBUTION_BOL: "/distribution/bill-of-lading",
    SALE_ORDER_MODULE:"/sale-order",
    ADD_SALE_ORDER_MODULE: "/sale-order/add",
    PURCHASE_ORDER_MODULE:"/purchase-order",
    ADD_PURCHASE_ORDER_MODULE: "/purchase-order/add",
    Delivered: "/distribution/delivered",
    FABRICATION_BOL: "/fabrication-supply/bill-of-landing",
    FABRICATION_DELIVERED: "/fabrication-supply/delivered",
    SHOP_SUPPLY_BOL: "/shop-supply/bill-of-landing",
    SHOP_SUPPLY_DELIVERED: "/shop-supply/delivered",
    EMAIL_TEMPLATE: "/email-template",
    QUICK_BOOK_CALLBACK: "/quick-book-callback",
    CHECKLIST_URL: "/checklist",
    DEALER_URL: "/checklist/dealer",
    TEAM_LEAD_URL: "/checklist/team-lead",
    QA_URL: "/checklist/qa",
    STOCK_SUPPLY: "/stock-supply",
    STOCK_ORDER: "/stock-supply/orders",
    STOCK_BILL_OF_LADING: "/stock-supply/bill-of-landing",
    STOCK_DELIVERED: "/stock-supply/delivered",
    ADD_SUBMITTAL: "/submission/add",
    PREVIEW_SUBMITTAL: "/submission/preview",
    OP_DASHBOARD_URL: "/dashboard/operation-manager",
    QA_DASHBOARD_URL: "/dashboard/qa",
    TL_DASHBOARD_URL: "/dashboard/team-leader",
    DETAILER_DASHBOARD_URL: "/dashboard/detailer",
    OP_ALL_TASKS: "/dashboard/operation-manager/all-tasks",
    OP_REVISION_REQUEST: "/dashboard/operation-manager/revision-request",
    OP_LOG_REQUEST: "/dashboard/operation-manager/log-request",
    TL_ALL_TASKS: "/dashboard/team-leader/all-tasks",
    TL_ASSIGNED_BY: "/dashboard/team-leader/assigned-by",
    TL_REVISION_REQUEST: "/dashboard/team-leader/revision-request",
    TL_LOG_REQUEST: "/dashboard/team-leader/log-request",
    

    ENDPOINT_LINKS: {
      SIGN_IN: "/api/auth/login",
      PDF_PREVIEW: "/api/document/order-download",
      FORGET_PASSWORD: "/api/auth/forgotpassword",
      RESET_PASSWORD: "/api/auth/resetpassword",
      LOG_OUT: "/api/auth/logout",
      INTUIT_AUTH: "/api/intuit/auth",
      UPLOAD_FILE: "/api/users/upload",
      GET_USER_DETAILS: "/api/users/me",
      INVOICE_DOWNLOAD: "/api/document/invoice-download",
      DOC_DOWNLOAD: "/api/document/download",
      INVOICE_SEND: "/api/document/invoice-send",
      INTUIT_DOWNLOAD: "/api/document/invoice-download",
      DISTRIBUTION_INTUIT_RESEND: "/api/users/invoice-send/distribution",
      FABRICATION_INTUIT_RESEND: "/api/users/invoice-send/fabrication",
      SHOP_INTUIT_RESEND: "/api/users/invoice-send/shop",
      STOCK_INTUIT_RESEND: "/api/users/invoice-send/stock",
      GOOGLE_MAP: "/api/users/gmap",
      PREVIEW_ORDER: "/api/document/order-view",
      XML_DOC_URL:"/api/xls/track-download",
      MERGE_PDF_URL:"/api/xls/pdf-download"
    },
  },
  image: {
    default_group:`${window.location.origin}/assets/images/default_group.webp`,
    default_user:`${window.location.origin}/assets/images/default_user.webp`,
    backgroundImage: `${window.location.origin}/assets/icons/bg.webp`,
    arrowleft: `${window.location.origin}/assets/icons/arrowleft.png`,
    filter: `${window.location.origin}/assets/icons/filter.svg`,
    sort: `${window.location.origin}/assets/icons/sort.svg`,
    SortList: `${window.location.origin}/assets/icons/SortList.svg`,
    FilterSort: `${window.location.origin}/assets/icons/FilterSort.svg`,
    sale: `${window.location.origin}/assets/icons/sale.svg`,
    UpAngle: `${window.location.origin}/assets/icons/UpAngle.svg`,
    Edit: `${window.location.origin}/assets/icons/Edit.svg`,
    Delete: `${window.location.origin}/assets/icons/Delete.svg`,
    home: `${window.location.origin}/assets/icons/home.png`,
    info: `${window.location.origin}/assets/icons/info.png`,
    Info: `${window.location.origin}/assets/icons/Info.svg`,
    xml: `${window.location.origin}/assets/icons/xml.svg`,
    Info1: `${window.location.origin}/assets/icons/info 1.svg`,
    true: `${window.location.origin}/assets/icons/true.png`,
    bag: `${window.location.origin}/assets/icons/bag.png`,
    box: `${window.location.origin}/assets/icons/box.png`,
    cart: `${window.location.origin}/assets/icons/cart.png`,
    doc: `${window.location.origin}/assets/icons/doc.png`,
    truck: `${window.location.origin}/assets/icons/truck.png`,
    user: `${window.location.origin}/assets/icons/user.png`,
    docdone: `${window.location.origin}/assets/icons/docdone.png`,
    docline: `${window.location.origin}/assets/icons/docline.png`,
    eye: `${window.location.origin}/assets/icons/eye.svg`,
    logout: `${window.location.origin}/assets/icons/logout.svg`,
    add: `${window.location.origin}/assets/icons/add.svg`,
    role: `${window.location.origin}/assets/icons/role.svg`,
    Reset: `${window.location.origin}/assets/icons/Reset.svg`,
    Mail: `${window.location.origin}/assets/icons/Mail.svg`,
    User: `${window.location.origin}/assets/icons/User.svg`,
    AngleDown: `${window.location.origin}/assets/icons/AngleDown.svg`,
    cancel: `${window.location.origin}/assets/icons/cancel.svg`,
    plus: `${window.location.origin}/assets/icons/plus.svg`,
    Send: `${window.location.origin}/assets/icons/Send.svg`,
    Dollar: `${window.location.origin}/assets/icons/Dollar.svg`,
    Reload: `${window.location.origin}/assets/icons/Reload.svg`,
    Upload: `${window.location.origin}/assets/icons/Upload.svg`,
    FileUpload: `${window.location.origin}/assets/icons/FileUpload.svg`,
    EmailSetup: `${window.location.origin}/assets/icons/EmailSetup.svg`,
    EmailTemplate: `${window.location.origin}/assets/icons/EmailTemplate.svg`,
    Timer: `${window.location.origin}/assets/icons/timer.svg`,
    ArrowUpRight: `${window.location.origin}/assets/icons/arrow-up-right.svg`,
    ArrowRight: `${window.location.origin}/assets/icons/arrow-right.svg`,
    FileAlt: `${window.location.origin}/assets/icons/file_alt.svg`,
    schedule: `${window.location.origin}/assets/icons/schedule.svg`,
    DeleteDetails: `${window.location.origin}/assets/icons/DeleteDetails.svg`,
    Download: `${window.location.origin}/assets/icons/Download.svg`,
    pdf: `${window.location.origin}/assets/icons/pdf.svg`,
    calendar: `${window.location.origin}/assets/icons/Vector.svg`,
    calendar1: `${window.location.origin}/assets/icons/calendar.svg`,
    StockSupply: `${window.location.origin}/assets/icons/StockSupply.svg`,
    checkList: `${window.location.origin}/assets/icons/checkList.svg`,
    assign: `${window.location.origin}/assets/icons/assign.svg`,
    ProjectManagement:`${window.location.origin}/assets/icons/ProjectManagement.svg`,
    chatIcon:`${window.location.origin}/assets/icons/chatIcon.svg`,
    personImg:`${window.location.origin}/assets/icons/Ellipse 143.png`,
    checkbox:`${window.location.origin}/assets/icons/Checkbox.png`,
    emptycheckbox:`${window.location.origin}/assets/icons/Rectangle 452.png`,
    viewDetails:`${window.location.origin}/assets/icons/ViewDetails.svg`,
    addIcon:`${window.location.origin}/assets/icons/addIcon.svg`,
    versionHistory:`${window.location.origin}/assets/icons/versionHistory.svg`,
    attahments:`${window.location.origin}/assets/icons/attachments.svg`,
    drawing1:`${window.location.origin}/assets/icons/drawing1.svg`,
    drawing2:`${window.location.origin}/assets/icons/drawing2.svg`,
    FileDownload:`${window.location.origin}/assets/icons/FileDownload.svg`,
    Submission:`${window.location.origin}/assets/icons/Submission.svg`,
    file:`${window.location.origin}/assets/icons/file.svg`,
    webMail:`${window.location.origin}/assets/icons/webMail.svg`,
    RightTick:`${window.location.origin}/assets/icons/RightTick.svg`,
    PlusIcon:`${window.location.origin}/assets/icons/PlusIcon.svg`,

    Map: `${window.location.origin}/assets/icons/Map.svg`,
    Shop: `${window.location.origin}/assets/icons/Shop.svg`,
    search:`${window.location.origin}/assets/icons/search.svg`,
    archive:`${window.location.origin}/assets/icons/archive.svg`,
    unarchive:`${window.location.origin}/assets/icons/Unarchive.svg`,
    Key:`${window.location.origin}/assets/icons/Key.svg`,
    ArrowLong:`${window.location.origin}/assets/icons/ArrowLong.svg`,
    notesheet:`${window.location.origin}/assets/icons/notesheet.svg`,
    Bold:`${window.location.origin}/assets/icons/Bold.svg`,
    Italic:`${window.location.origin}/assets/icons/Italic.svg`,
    Underline:`${window.location.origin}/assets/icons/Underline.svg`,
    Strike:`${window.location.origin}/assets/icons/Strike.svg`,
    Quote:`${window.location.origin}/assets/icons/Quote.svg`,
    OrderList:`${window.location.origin}/assets/icons/OrderList.svg`,
    UnOrderList:`${window.location.origin}/assets/icons/UnOrderList.svg`,
    Code:`${window.location.origin}/assets/icons/Code.svg`,
    CodeBlock:`${window.location.origin}/assets/icons/CodeBlock.svg`,
    messageIcon:`${window.location.origin}/assets/icons/messageIcon.svg`,
    groupChatIcon:`${window.location.origin}/assets/icons/groupChatIcon.svg`,
    profileImage:`${window.location.origin}/assets/icons/pi.webp`,
    emoji:`${window.location.origin}/assets/icons/emoji.svg`,
    cameraPlusIcon:`${window.location.origin}/assets/icons/cameraPlus.svg`,
    inventory:`${window.location.origin}/assets/icons/inventory.svg`,
    personImg2:`${window.location.origin}/assets/icons/pi2.webp`,
    personImg3:`${window.location.origin}/assets/icons/pi3.webp`,
    personImg4:`${window.location.origin}/assets/icons/pi4.webp`,
    RoundedPlusIcon:`${window.location.origin}/assests/icons/RoundedPlusIcon.svg`,
    Print:`${window.location.origin}/assets/icons/Print.svg`,
    ChatIllustration:`${window.location.origin}/assets/icons/ChatIllustration.svg`,
    groupProfile:`${window.location.origin}/assets/icons/groupProfile.svg`,
    userProfile:`${window.location.origin}/assets/icons/userProfile.svg`,
    addMember:`${window.location.origin}/assets/icons/addMember.svg`,
    noUser:`${window.location.origin}/assets/icons/No_contacts.svg`,

  },
  allReducers: (state: any) => state?.allReducers,
};
export const INITIAL_PERMISSION = 'userService:changePassword,emailTemplateService:list,emailTemplateService:get,cityService:list,stateService:list,countryService:list'
export const adminsidebarcontent = [
  {
    title: "Dashboard",
    route: App_url.link.DASHBOARD_URL,
    icon: App_url.image.home,
  },
  { 
    title: "Internal Users",
    route: App_url.link.INTERNAL_USER_URL,
    icon: App_url.image.user,
  },
  {
    title: "External Users",
    route: App_url.link.EXTERNAL_USER_URL,
    icon: App_url.image.bag,
  },
  // {
  //   title: "Customers",
  //   route: CUSTOMER_URL,
  //   icon: App_url.image.bag,
  // },
  // {
  //   title: "Projects",
  //   route: PROJECT_URL,
  //   icon: App_url.image.bag,
  // },

  {
    title: "All Masters",
    route: App_url.link.TYPE_OF_PROJECT_URL,
    icon: App_url.image.doc,
  },
  {
    title: "Email Template",
    route: App_url.link.EMAIL_TEMPLATE,
    icon: App_url.image.EmailTemplate,
  },
  {
    title: "Sender Email Setup",
    route: App_url.link.SENDER_EMAIL_SETUP,
    icon: App_url.image.EmailSetup,
  },
  {
    title: "Api Credentials",
    route: App_url.link.QuickBook,
    icon: App_url.image.Key,
  },
  // {
  //   title: "Fabrication Supply",
  //   route: FABRICATION_URL,
  //   icon: App_url.image.box,
  // },
  // {
  //   title: "Stock Supply",
  //   route: STOCK_SUPPLY_URL,
  //   icon: App_url.image.cart,
  // },
  // {
  //   title: "Logistics",
  //   route: LOGISTIC_URL,
  //   icon: App_url.image.truck,
  // },
  // {
  //   title: "Inventory",
  //   route: INVENTORY_URL,
  //   icon: App_url.image.docdone,
  // },
  {
    title: "Roles And Permissions",
    route: App_url.link.ROLES_AND_PERMISSION,
    icon: App_url.image.role,
  },
  {
    title: "Checklist",
    route: App_url.link.DEALER_URL,
    icon: App_url.image.checkList,
  },
];

export const frontofficesidebarcontent = [
  {
    title: "Dashboard",
    route: App_url.link.DASHBOARD_URL,
    icon: App_url.image.home,
    action_type: "frontDashboardService:get",
    permission: dashboardPermission,
    group_id: 1,
  },
  {
    title: "Customers",
    route: App_url.link.CUSTOMER_URL,
    icon: App_url.image.User,
    action_type: "customerService:list",
    permission: customerServicePermission,
    group_id: 2,
    className:"mt-3"
  },
  {
    title: "Supplier",
    route: App_url.link.SUPPLIER_URL,
    icon: App_url.image.Shop,
    action_type: "supplierService:list",
    permission: supplierServicePermission,
    group_id: 2,
  },
  {
    title: "Projects",
    route: App_url.link.PROJECT_URL,
    icon: App_url.image.bag,
    action_type: "projectService:list",
    permission: projectServicePermission,
    group_id: 2,
    
  },
  {
    title: "Proposal",
    route: App_url.link.PROPOSAL_URL,
    icon: App_url.image.doc,
    action_type: "proposalService:list",
    permission: proposalServicePermission,
    group_id: 3,
  },
  {
    title: "Projects",
    route: App_url.link.PROJECT_URL,
    icon: App_url.image.bag,
    action_type: "backOfficeProjectService:list",
    permission: backOfficeProjectServicePermission,
        
  },
  {
    title: "Project Management",
    route: App_url.link.PROJECT_MANAGEMENT_URL,
    icon: App_url.image.ProjectManagement,
    action: "list",
    action_type: "projectService:list",
    permission: projectManagementPermission,
  },
  {
    title: "Chat",
    route: App_url.link.CHAT_URL,
    icon: App_url.image.chatIcon,
    action: "list",
    action_type:"chatService",
    permission: chatPermission
  },
  {
    title: "Submission",
    route: App_url.link.SUBMISSION_URL,
    icon: App_url.image.Submission,
    action: "list",
    action_type: "submissionService:list",
    permission: submissionPermission
  },
  {
    title: "Web Mail",
    route: App_url.link.WEBMAIL_URL,
    icon: App_url.image.webMail,
    action: "list",
    action_type: "gmailService:list",
    permission: webMailPermission
  },
  {
    title: "Sale Order",
    route: App_url.link.SALE_ORDER_MODULE,
    icon: App_url.image.sale,
    action_type: "saleOrderService:list",
    permission: saleOrderPermission,
    group_id: 3,
  },
  {
    title: "Purchase Order",
    route: App_url.link.PURCHASE_ORDER_MODULE,
    icon: App_url.image.cart,
    action_type: "purchaseOrderService:list",
    permission: purchaseOrderPermission,
    group_id: 3,
  },
  
  {
    title: "Distribution",
    route: App_url.link.DISTRIBUTION,
    icon: App_url.image.docline,
    action_type: "inquiryService:list,inquirySaleService:list",
    permission: inquiryServicePermission,
    group_id: 4,
  },
  
  {
    title: "Shop Supply",
    route: App_url.link.SHOP_SUPPLY_URL,
    icon: App_url.image.box,
    action_type: "shopService:list",
    permission: shopSupplyPermission,
    group_id: 4,
  },
  
  {
    title: "Logistics",
    route: App_url.link.LOGISTIC_URL,
    icon: App_url.image.truck,
    action_type: "logisticService:list",
    permission: logisticServicePermission,
    group_id: 4,
  },
  {
    title: "Inventory",
    route: App_url.link.INVENTORY_URL,
    icon: App_url.image.inventory,
    action_type: "inventoryService:list",
    permission: inventoryServicePermission,
    group_id: 5,
  },
  {
    title: "Stock Transfer",
    route: App_url.link.STOCK_TRANSFER_URL,
    icon: App_url.image.cart,
    action_type: "inventoryTransferService:list",
    permission: stockTransferServicePermission,
    group_id: 5,
  },
  {
    title: "Edit Inventory",
    route: App_url.link.EDIT_INVENTORY_URL,
    icon: App_url.image.Edit,
    action_type: "inventoryService:edit",
    permission: editInventoryPermission,
    group_id: 5,
  },
];



export const initialState = {
  items: [],
  totalCount: 0,
  optionList: [],
};

export const initialUserData = {
  status: "",
  user: {
    id: "",
    email: "",
    active: false,
    password: "",
    is_admin: false,
    role_permissions: "",
    api_permissions: "",
    name: "",
    emp_id: "",
    user_type: "",
    role: "",
  },
  access_token: "",
};
export const backOfficeRolePermission = [
  {
    name: "Project",
    type: "projectService",
    permissions: [
      {
        name: "create",
        api_list: "projectService:create",
        label: "Create",
        value: false,
      },
      {
        name: "update",
        api_list: "projectService:update,projectService:updateStatus",
        label: "Update",
        value: false,
      },
      {
        name: "delete",
        api_list: "projectService:delete",
        label: "Delete",
        value: false,
      },
      {
        name: "list",
        api_list: "projectService:list,projectService:get",
        label: "View Only",
        value: false,
      },
    ],
    status: false,
  },
  {
    name: "Customers",
    type: "customerService",
    permissions: [
      {
        name: "create",
        label: "Create",
        value: false,
        api_list: "customerService:create",
      },
      {
        name: "update",
        label: "Update",
        value: false,
        api_list: "customerService:update,customerService:updateStatus",
      },
      {
        name: "delete",
        label: "Delete",
        value: false,
        api_list: "customerService:delete",
      },
      {
        name: "list",
        label: "View Only",
        value: false,
        api_list: "customerService:list,customerService:get",
      },
    ],
    status: false,
  },
];
export const roleHeader = [
  {
    name: "Inquiry Service",
    type: "inquiryService",
    permissions: [
      {
        name: "create",
        label: "Create",
        api_list:
          "inquiryService:create,inquiryService:get,inquiryService:supplier_sale,inquiryService:supplier_price,inquiryService:supplier,inquiryService:sale",
        value: false,
      },
      {
        name: "update",
        label: "Update",
        api_list: "inquiryService:update,inquiryService:updateStatus",
        value: false,
      },
      {
        name: "delete",
        label: "Delete",
        api_list: "inquiryService:delete",
        value: false,
      },
      {
        name: "list",
        label: "View Only",
        api_list: "inquiryService:list,inquiryService:supplier_list",
        value: false,
      },
    ],
    status: false,
  },
  {
    name: "Coating Service",
    type: "coatingService",
    permissions: [
      {
        name: "create",
        label: "Create",
        api_list: "coatingService:get,coatingService:list",
        value: false,
      },
      {
        name: "update",
        label: "Update",
        api_list: "coatingService:update",
        value: false,
      },
      {
        name: "delete",
        label: "Delete",
        api_list: "coatingService:delete",
        value: false,
      },
      {
        name: "list",
        label: "View Only",
        api_list: "coatingService:list",
        value: false,
      },
    ],
    status: false,
  },
  {
    name: "Size & Specification",
    type: "sizeSpecificationService",
    permissions: [
      {
        name: "create",
        label: "Create",
        api_list: "sizeSpecificationService:get,sizeSpecificationService:list",
        value: false,
      },
      {
        name: "update",
        label: "Update",
        api_list: "sizeSpecificationService:update",
        value: false,
      },
      {
        name: "delete",
        label: "Delete",
        api_list: "sizeSpecificationService:delete",
        value: false,
      },
      {
        name: "list",
        label: "View Only",
        api_list: "sizeSpecificationService:list",
        value: false,
      },
    ],
    status: false,
  },
  {
    name: "Location Facility",
    type: "locationFacilityService",
    permissions: [
      {
        name: "create",
        label: "Create",
        api_list: "locationFacilityService:get,locationFacilityService:list",
        value: false,
      },
      { name: "update", label: "Update", api_list: "", value: false },
      { name: "delete", label: "Delete", api_list: "", value: false },
      {
        name: "list",
        label: "View Only",
        api_list: "locationFacilityService:list",
        value: false,
      },
    ],
    status: false,
  },
  {
    name: "Sale Order",
    type: "inquirySaleService,intuitService",
    permissions: [
      {
        name: "create",
        label: "Create",
        api_list: "inquirySaleService:create,inquirySaleService:get",
        value: false,
      },
      {
        name: "update",
        label: "Update",
        api_list:
          "inquirySaleService:accept,inquirySaleService:po,inquirySaleService:schedule,inquirySaleService:deliver,inquirySaleService:schedulePadlet",
        value: false,
      },
      {
        name: "delete",
        label: "Delete",
        api_list: "inquirySaleService:reject,inquirySaleService:archive",
        value: false,
      },
      // {
      //   name: "list",
      //   label: "View Only",
      //   api_list:
      //     "inquirySaleService:list,intuitService:items,intuitService:taxRate,intuitService:customer,intuitService:invoice",
      //   value: false,
      // },
    ],
    status: false,
  },
  {
    name: "Supplier",
    type: "supplierService,supplierTypeService",
    permissions: [
      {
        name: "create",
        label: "Create",
        value: false,
        api_list: "supplierTypeService:create,supplierService:create",
      },
      {
        name: "update",
        label: "Update",
        value: false,
        api_list:
          "supplierTypeService:update,supplierService:update,supplierTypeService:updateStatus,supplierService:updateStatus",
      },
      {
        name: "delete",
        label: "Delete",
        value: false,
        api_list: "supplierTypeService:delete,supplierService:delete",
      },
      {
        name: "list",
        label: "View Only",
        value: false,
        api_list:
          "supplierTypeService:list,supplierTypeService:list,supplierService:get,supplierService:list",
      },
    ],
    status: false,
  },
  {
    name: "Tax",
    type: "taxService",
    permissions: [
      {
        name: "create",
        label: "Create",
        value: false,
        api_list: "taxService:create",
      },
      {
        name: "update",
        label: "Update",
        value: false,
        api_list: "taxService:update,taxService:updateStatus",
      },
      {
        name: "delete",
        label: "Delete",
        value: false,
        api_list: "taxService:delete",
      },
      {
        name: "list",
        label: "View Only",
        value: false,
        api_list: "taxService:list,taxService:get",
      },
    ],
    status: false,
  },
  {
    name: "Product Service",
    type: "productService",
    permissions: [
      {
        name: "create",
        label: "Create",
        value: false,
        api_list: "productService:create",
      },
      {
        name: "update",
        label: "Update",
        value: false,
        api_list: "productService:update,productService:updateStatus",
      },
      {
        name: "delete",
        label: "Delete",
        value: false,
        api_list: "productService:delete",
      },
      {
        name: "list",
        label: "View Only",
        value: false,
        api_list: "productService:list,productService:get",
      },
    ],
    status: false,
  },
  {
    name: "Delivery List",
    type: "deliveryByService",
    permissions: [
      {
        name: "create",
        label: "Create",
        value: false,
        api_list: "deliveryByService:create",
      },
      {
        name: "update",
        label: "Update",
        value: false,
        api_list: "deliveryByService:update,deliveryByService:updateStatus",
      },
      {
        name: "delete",
        label: "Delete",
        value: false,
        api_list: "deliveryByService:delete",
      },
      {
        name: "list",
        label: "View Only",
        value: false,
        api_list: "deliveryByService:list,deliveryByService:get",
      },
    ],
    status: false,
  },
  {
    name: "Grade Service",
    type: "gradeService",
    permissions: [
      {
        name: "create",
        label: "Create",
        value: false,
        api_list: "gradeService:create",
      },
      {
        name: "update",
        label: "Update",
        value: false,
        api_list: "gradeService:update,gradeService:updateStatus",
      },
      {
        name: "delete",
        label: "Delete",
        value: false,
        api_list: "gradeService:delete",
      },
      {
        name: "list",
        label: "View Only",
        value: false,
        api_list: "gradeService:list,gradeService:get",
      },
    ],
    status: false,
  },
  {
    name: "Project Management",
    type: "projectService",
    permissions: [
      {
        name: "create",
        label: "Create",
        value: false,
        api_list: "projectService:create",
      },
      {
        name: "update",
        label: "Update",
        value: false,
        api_list:
          "projectService:update,projectService:updateStatus,projectService:updatePricing",
      },
      {
        name: "delete",
        label: "Delete",
        value: false,
        api_list: "projectService:delete",
      },
      {
        name: "list",
        label: "View Only",
        value: false,
        api_list: "projectService:list,projectService:get",
      },
    ],
    status: false,
  },
  {
    name: "Project Type",
    type: "projectTypeService",
    permissions: [
      {
        name: "create",
        label: "Create",
        value: false,
        api_list: "projectTypeService:create",
      },
      {
        name: "update",
        label: "Update",
        value: false,
        api_list: "projectTypeService:update,projectTypeService:updateStatus",
      },
      {
        name: "delete",
        label: "Delete",
        value: false,
        api_list: "projectTypeService:delete",
      },
      {
        name: "list",
        label: "View Only",
        value: false,
        api_list: "projectTypeService:list,projectTypeService:get",
      },
    ],
    status: false,
  },
  {
    name: "Project Scope",
    type: "projectScopeService",
    permissions: [
      {
        name: "create",
        label: "Create",
        value: false,
        api_list: "projectScopeService:create",
      },
      {
        name: "update",
        label: "Update",
        value: false,
        api_list: "projectScopeService:update,projectScopeService:updateStatus",
      },
      {
        name: "delete",
        label: "Delete",
        value: false,
        api_list: "projectScopeService:delete",
      },
      {
        name: "list",
        label: "View Only",
        value: false,
        api_list: "projectScopeService:list,projectScopeService:get",
      },
    ],
    status: false,
  },
  {
    name: "Scope of Work",
    type: "scopeOfWorkService",
    permissions: [
      {
        name: "create",
        label: "Create",
        value: false,
        api_list: "scopeOfWorkService:create",
      },
      {
        name: "update",
        label: "Update",
        value: false,
        api_list: "scopeOfWorkService:update,scopeOfWorkService:updateStatus",
      },
      {
        name: "delete",
        label: "Delete",
        value: false,
        api_list: "scopeOfWorkService:delete",
      },
      {
        name: "list",
        label: "View Only",
        value: false,
        api_list: "scopeOfWorkService:list,scopeOfWorkService:get",
      },
    ],
    status: false,
  },
  {
    name: "Customers",
    type: "customerService",
    permissions: [
      {
        name: "create",
        label: "Create",
        value: false,
        api_list: "customerService:create",
      },
      {
        name: "update",
        label: "Update",
        value: false,
        api_list: "customerService:update,customerService:updateStatus",
      },
      {
        name: "delete",
        label: "Delete",
        value: false,
        api_list: "customerService:delete",
      },
      {
        name: "list",
        label: "View Only",
        value: false,
        api_list: "customerService:list,customerService:get",
      },
    ],
    status: false,
  },
  // {
  //   name: "Fabrication Supply",
  //   type: "fabricationService",
  //   permissions: [
  //     {
  //       name: "create",
  //       label: "Create",
  //       value: false,
  //       api_list:
  //         "fabricationService:create,fabricationScheduleService:schedule,fabricationScheduleService:schedulePadlet,fabricationScheduleService:deliver",
  //     },
  //     {
  //       name: "update",
  //       label: "Update",
  //       value: false,
  //       api_list: "fabricationService:update,fabricationService:updateStatus",
  //     },
  //     {
  //       name: "delete",
  //       label: "Delete",
  //       value: false,
  //       api_list: "fabricationService:delete",
  //     },
  //     {
  //       name: "list",
  //       label: "View Only",
  //       value: false,
  //       api_list: "fabricationService:list,fabricationService:get",
  //     },
  //   ],
  //   status: false,
  // },
  // {
  //   name: "Stock Supply",
  //   type: "stockService",
  //   permissions: [
  //     {
  //       name: "create",
  //       label: "Create",
  //       value: false,
  //       api_list: "stockService:create",
  //     },
  //     {
  //       name: "update",
  //       label: "Update",
  //       value: false,
  //       api_list: "stockService:update,stockService:updateStatus",
  //     },
  //     {
  //       name: "delete",
  //       label: "Delete",
  //       value: false,
  //       api_list: "stockService:delete",
  //     },
  //     {
  //       name: "list",
  //       label: "View Only",
  //       value: false,
  //       api_list: "stockService:list,stockService:get",
  //     },
  //   ],
  //   status: false,
  // },
  {
    name: "Inventory",
    type: "inventoryService",
    permissions: [
      {
        name: "create",
        label: "Create",
        value: false,
        api_list: "inventoryService:create,productLengthService:list",
      },
      {
        name: "update",
        label: "Update",
        value: false,
        api_list: "inventoryService:update,inventoryService:updateStatus",
      },
      {
        name: "delete",
        label: "Delete",
        value: false,
        api_list: "inventoryService:delete",
      },
      {
        name: "list",
        label: "View Only",
        value: false,
        api_list:
          "inventoryService:list,inventoryService:stock,inventoryService:get",
      },
    ],
    status: false,
  },
  {
    name: "Product Length ",
    type: "productLengthService",
    permissions: [
      {
        name: "create",
        label: "Create",
        value: false,
        api_list: "productLengthService:create,productLengthService:list",
      },
      {
        name: "update",
        label: "Update",
        value: false,
        api_list:
          "productLengthService:update,productLengthService:updateStatus",
      },
      {
        name: "delete",
        label: "Delete",
        value: false,
        api_list: "productLengthService:delete",
      },
      {
        name: "list",
        label: "View Only",
        value: false,
        api_list:
          "productLengthService:list,productLengthService:stock,inventoryService:get",
      },
    ],
    status: false,
  },
  {
    name: "Email",
    permissions: [
      { name: "create", label: "Create", api_list: "get", value: false },
    ],
    status: false,
  },
  {
    name: "Intuit Service",
    type: "intuitService",
    permissions: [
      {
        name: "create",
        label: "Create",
        api_list: "items,taxRate",
        value: false,
      },
    ],
    status: false,
  },
];

export const SortingFiled = [
  "name",
  "emp_id",
  "address",
  "customer_name",
  "project_name",
  "term_condition",
  "role_name",
  "role_description",
  "size_name",
  "grade_name",
  "coating_name",
  "scope_of_work_name",
  "project_scope_name",
  "length_name",
];

export const projectNav = {
  toggleData: [
    { label: "Project Details", link: App_url.link.ADD_PROJECT_URL },
    {
      label: "Pricing Details",
      link: App_url.link.ADD_PRICE_URL,
      disabled: true,
    },
    {
      label: "Escalation Details",
      link: App_url.link.ADD_PRICE_URL,
      disabled: true,
    },
  ],
};

export const proposalNav = {
  toggleData: [
    { label: "Proposal Details", link: App_url.link.ADD_PROPOSAL_URL },
    {
      label: "Aggreement",
      link: App_url.link.ADD_PROPOSAL_AGGREEMENT_URL,
      disabled: true,
    },
  ],
};

export const editProposalNav =(id:string) => {
  return{

    toggleData: [
      {
        label: "Proposal Details",
        link: `${App_url.link.ADD_PROPOSAL_URL}/${id}`,
      },
      {
        label: "Aggreement",
        link: `${App_url.link.ADD_PROPOSAL_AGGREEMENT_URL}/${id}`,
      },
    ],
  }
};

export const editProjectNav = (id) => {
  return {
    toggleData: [
      {
        label: "Project Details",
        link: `${App_url.link.ADD_PROJECT_URL}/${id}`,
      },
      {
        label: "Pricing Details",
        link: `${App_url.link.ADD_PRICE_URL}/${id}`,
      },
      {
        label: "Escalation Details",
        link: `${App_url.link.ADD_ESCALATION_URL}/${id}`,
      },
    ],
  };
};

export const back_office_project_nav = {
  toggleData: [],
};
export const editBackOfficeProjectNav = (id) => {
  return {
    toggleData: [],
  };
};
// const NumberRegex = /^\d+(\.\d{1,2})?$/
export const ValidateForm = {
  stockSeries:()=>({
    required: `Pickup No. is required`,
    pattern: {
      value: /^[A-Za-z]{3}\d{4}$/,
      message: `Pickup No. must start with 3 letters followed by 4 digits`,
    },
  }),
  positiveNumber: (name) => ({
    required: `${name} is required`,
    pattern: {
      value: /^[0-9]+$/, // Pattern to ensure a positive integer
      message: `Invalid ${name}. Must be a positive integer.`,
    },
  }),
  quantityNumber: (name) => ({
    required: `${name} is required`,
    pattern: {
      value: /^\s*(?!0\d)\d*(\.\d{1,4})?\s*$/, // Pattern to ensure a positive number with up to two decimal places
      message: `Invalid ${name}. Must be a positive number with up to 4 decimal places.`,
    },
  }),
  
  NumberZeroRequired: (Price) => ({
    required: `${Price} is required`,
    maxLength: {
      value: 255,
      message: `${Price} cannot exceed 255 characters`,
    },
    pattern: {
      value: /^(0|[1-9]\d*)$/, // Pattern to ensure a positive integer or a single zero
      message: `Invalid ${Price}. Must be a positive integer or zero.`,
    },
  }),
  buying_price: {
    required: "Buying Price is required",
    maxLength: {
      value: 255,
      message: "Buying Price cannot exceed 255 characters",
    },
    pattern: {
      value: /^(?!0(\.0+)?$)([1-9]\d*|0)(\.\d{1,4})?$/, // Pattern for positive numbers with up to 2 decimal places, excluding zero
      message: `Buying Price must be a positive number with up to 4 decimal places (not zero)`,
    },
    validate: (value) => {
      if (value == 0) {
        return `Buying Price must be a positive number with up to 4 decimal places (not zero)`;
      }
    },
  },
  textEditor: (Name)=>({
    validate:(value)=>{
      if(value == "" || value == "<p><br></p>"){
        return `${Name} is required`
      }else{
        return true;
      }
    }
  }),
  Digit4: (Price) => ({
    maxLength: {
      value: 255,
      message: `${Price} cannot exceed 255 characters`,
    },
    pattern: {
      value: /^(0|[1-9]\d*)(\.\d{1,4})?$/,
      message: `${Price} must be a positive number with up to 4 decimal places (not zero)`,
    },
    validate: (value) => {
      if (value === "") {
        return true;
      }
    },
  }),
  Digit2: (Price) => ({
    maxLength: {
      value: 255,
      message: `${Price} cannot exceed 255 characters`,
    },
    pattern: {
      value: /^(0|[1-9]\d*)(\.\d{1,2})?$/,
      message: `${Price} must be a positive number with up to 2 decimal places`,
    },
    validate: (value) => {
      if (value === "") {
        return true;
      }
    },
  }),
  validateNumber4Digit:(Price, value)=>{
    // Check if required
   if (!value) {
     return `${Price} is required`;
   }

   // Check if length exceeds maxLength
   if (value.length > 255) {
     return `${Price} cannot exceed 255 characters`;
   }

   // Check if pattern is violated
   const pattern = /^(?!0(\.0+)?$)([1-9]\d*|0)(\.\d{1,4})?$/;
   if (!pattern.test(value)) {
     return `${Price} must be a positive number with up to 4 decimal places (not zero)`;
   }

   // Check if value is zero
   if (parseFloat(value) == 0) {
     return `${Price} must be a positive number with up to 4 decimal places (not zero)`;
   }

   // Check number of decimal places
   const decimalPlaces = value?.split?.(".")?.[1]?.length || 0;
   if (decimalPlaces > 4) {
     return `${Price} must have no more than 4 decimal places`;
   }

   return true; // Validation passed
 },
 numberWith4Digit: (Price) => ({
  maxLength: {
    value: 255,
    message: `${Price} cannot exceed 255 characters`,
  },
  pattern: {
    value: /^(?!0(\.0+)?$)([1-9]\d*|0)(\.\d{1,4})?$/, // Pattern for positive numbers with up to 2 decimal places, excluding zero
    message: `${Price} must be a positive number with up to 4 decimal places (not zero)`,
  },
  validate: (value) => {
    if(value == ""){
      return true
    }
    if (value == 0) {
      return `${Price} must be a positive number with up to 4 decimal places (not zero)`;
    }
  },
}),
  Number4Digit: (Price) => ({
    required: `${Price} is required`,
    maxLength: {
      value: 255,
      message: `${Price} cannot exceed 255 characters`,
    },
    pattern: {
      value: /^(?!0(\.0+)?$)([1-9]\d*|0)(\.\d{1,4})?$/, // Pattern for positive numbers with up to 2 decimal places, excluding zero
      message: `${Price} must be a positive number with up to 4 decimal places (not zero)`,
    },
    validate: (value) => {
      if (value == 0) {
        return `${Price} must be a positive number with up to 4 decimal places (not zero)`;
      }
    },
  }),
  Number4DigitWithZero: (Price) => ({
    required: `${Price} is required`,
    maxLength: {
      value: 255,
      message: `${Price} cannot exceed 255 characters`,
    },
    pattern: {
      value: /^\d+(\.\d{0,4})?$/, // Pattern for positive numbers with up to 2 decimal places, excluding zero
      message: `${Price} must be a positive number with up to 4 decimal places`,
    },
   
  }),
  Number4DigitWithZero2: (Price) => ({
    maxLength: {
      value: 255,
      message: `${Price} cannot exceed 255 characters`,
    },
    pattern: {
      value: /^\d+(\.\d{0,4})?$/, // Pattern for positive numbers with up to 2 decimal places, excluding zero
      message: `${Price} must be a positive number with up to 4 decimal places`,
    },
   
  }),
  Number: (Price) => ({
    maxLength: {
      value: 255,
      message: `${Price} cannot exceed 255 characters`,
    },
    pattern: {
      value: /^[1-9]\d*$/, // Pattern to ensure a positive integer
      message: `Invalid ${Price}. Must be a positive integer.`,
    },
  }),
  NumberRequired: (price, length = 255) => ({
    required: `${price} is required`,
    maxLength: {
      value: length,
      message: `${price} cannot exceed ${length} characters`,
    },
    pattern: {
      value: /^[1-9]\d*$/, // Pattern to ensure a positive integer
      message: `Invalid ${price}. Must be a positive number.`,
    },
    validate: (value) => {
      if (value == ""|| value?.includes?.('.')) {
        return `${price} is required`;
      }
      return true;
    },
  }),
  required: (name, field_name = "") => ({
    required: `${name} is required`,
    validate: (value) => {
      const field = !field_name ? value : value[field_name];
      if (field == "") {
        return `${name} is required`;
      }
      return true;
    },
  }),
  seller_price: (price) => ({
    required: "Seller Price is required",
    maxLength: {
      value: 255,
      message: "Seller Price cannot exceed 255 characters",
    },
    pattern: {
      value: /^\d+(\.\d{1,4})?$/, // Pattern for up to 2 decimal places
      message: "Seller Price must be a number with up to 4 decimal places",
    },
    validate: (value) => {
      const numericValue = parseFloat(value);
      const minValue = parseFloat(price);
      return (
        numericValue > minValue ||
        "Seller Price must be greater than Buying Price"
      );
    },
  }),
  margin: {
    required: "Margin is required",
    maxLength: {
      value: 255,
      message: "Margin cannot exceed 255 characters",
    },
    pattern: {
      value: /^\d+(\.\d{1,4})?$/, // Pattern for up to 2 decimal places
      message: "Margin must be a number with up to 4 decimal places",
    },
  },
  product_qty: {
    required: "Quantity is required",
    maxLength: {
      value: 255,
      message: "Quantity cannot exceed 255 characters",
    },
    pattern: {
      value: /^[0-9]+$/,
      message: "Quantity must be a number",
    },
  },
  textWithMaxLength: (text, length = 255, field_name = "") => ({
    required: `${text} is required`,
    maxLength: {
      value: 255,
      message: `${text} cannot exceed ${length} characters`,
    },
    validate: (value) => {
      const field = !field_name ? value : value[field_name];
      if (!field) {
        return `${text} is required`;
      }
      if (field.length > length) {
        return `${text} cannot exceed ${length} characters`;
      }
      return true;
    },
  }),
  nameMaxLength: (text, length = 255) => ({
    pattern: {
      value: /^[a-zA-Z\s'-]+$/,
      message: "Invalid name format",
    },
    maxLength: {
      value: 255,
      message: `${text} cannot exceed ${length} characters`,
    },
  }),
  nameWithMaxLength: (text, length = 255) => ({
    required: `${text} is required`,
    pattern: {
      value: /^[a-zA-Z\s'-]+$/,
      message: "Invalid name format",
    },
    maxLength: {
      value: 255,
      message: `${text} cannot exceed ${length} characters`,
    },
  }),
  ein_number: (text) => ({
    pattern: {
      value: /^\d{2}-\d{7}$/,
      message: "EIN Number must be in the format XX-XXXXXXX",
    },
    maxLength: {
      value: 10, // Length considering the hyphen
      message: "EIN Number cannot exceed 10 characters",
    },
  }),
  maxLength: (text, length = 5000) => ({
    maxLength: {
      value: length,
      message: `${text} cannot exceed ${length} characters`,
    },
  }),
  shipping_address: {
    required: "Shipping Address is required",
    maxLength: {
      value: 5000,
      message: "Shipping Address cannot exceed 5000 characters",
    },
    minLength: {
      value: 5,
      message: "Shipping Address cannot exceed 5 characters",
    },
  },
  textWithMinLength: (
    name,
    minLength = 5,
    maxLength = 255,
    field_name = ""
  ) => ({
    required: `${name} is required`,
    maxLength: {
      value: maxLength,
      message: `${name} cannot exceed ${maxLength} characters`,
    },
    minLength: {
      value: minLength,
      message: `${name} cannot exceed ${minLength} characters`,
    },
    validate: (value) => {
      const field = !field_name ? value : value[field_name];
      if (!field) {
        return `${name} is required`;
      }
      if (field.length > maxLength) {
        return `${name} cannot exceed ${maxLength} characters`;
      }
      if (field.length < minLength) {
        return `${name} cannot exceed ${minLength} characters`;
      }
      return true;
    },
  }),
  billing_address: {
    required: "Billing Address is required",
    maxLength: {
      value: 5000,
      message: "Billing Address cannot exceed 5000 characters",
    },
    minLength: {
      value: 5,
      message: "Billing Address cannot exceed 5 characters",
    },
  },
  tax_percentage: {
    required: "Tax Percentage is required",
    pattern: {
      value: /^\d+(\.\d{1,4})?$/, // Allows numbers with up to 2 decimal places
      message:
        "Tax Percentage must be a valid number with up to 4 decimal places",
    },
    min: {
      value: 0,
      message: "Tax Percentage cannot be less than 0",
    },
    max: {
      value: 100,
      message: "Tax Percentage cannot exceed 100",
    },
  },
  tax_amount: {
    required: "Tax Amount is required",
    pattern: {
      value: /^\d+(\.\d{1,4})?$/, // Allows numbers with up to 2 decimal places
      message: "Tax Amount must be a valid number with up to 4 decimal places",
    },
    min: {
      value: 0,
      message: "Tax Amount cannot be less than 0",
    },
  },
  shipping_amount: {
    required: "Shipping Amount is required",
    pattern: {
      value: /^\d+(\.\d{1,4})?$/, // Pattern for up to 2 decimal places
      message:
        "Shipping Amount must be a valid number with up to 4 decimal places",
    },
    min: {
      value: 0,
      message: "Shipping Amount cannot be less than 0",
    },
  },
  emailValidation: (name, length = 255) => ({
    required: `${name} is required`,
    pattern: {
      value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
      message: "Invalid email format",
    },
    maxLength: {
      value: length,
      message: `${name} cannot exceed ${length} characters`,
    },
  }),
  EmailValid: (name, length = 255) => ({
    pattern: {
      value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
      message: "Invalid email format",
    },
    maxLength: {
      value: length,
      message: `${name} cannot exceed ${length} characters`,
    },
  }),
};


// export const terms_and_conditions = `Wire information
// Company Name: KAS REBAR LLC DBA KAS METALS
// Registered Address: 304 4th Street, Jersey City, NJ 07302
// Bank Name: Bank Of America
// Routing number: 021200339 (paper & electronic)
// 026009593 (wires)
// Account Number: 381071004424
// Bank Address: 115 W 42nd St NY 10036`;

export const terms_and_conditions = `Wire information

Company Name: KAS METALS

Registered Address: 304 4th Street, Jersey City, NJ 07302

Bank Name: Bank Of America

Routing number: 021200339 (paper & electronic)
026009593 (wires)

Account Number: 381071004424

Bank Address: 115 W 42nd St NY 10036`;



export const distributionMailKeywords=[
  {name:"BillAmount",value:"BillAmount", page:"Purchase Order Email,Sale Order Email,Invoice Email,"}, // Sale, Purchase, Invoice
  {name:"BillToCompanyName",value:"BillToCompanyName", page:"Purchase Order Email,Sale Order Email,Invoice Email,"}, // Sale, Purchase, Invoice
  {name:"BillToAddress",value:"BillToAddress", page:"Purchase Order Email,Sale Order Email,Inquiry Email,Invoice Email,"}, // Inquiry, Sale, Purchase, Invoice
  {name:"BolNo",value:"BolNo", page:"Invoice Email,"}, //Invoice
  {name:"RFQDate",value:"RFQDate", page:"Inquiry Email,"}, //Invoice
  {name:"CustomerEmail",value:"CustomerEmail", page:"Purchase Order Email,Sale Order Email,Inquiry Email,Invoice Email,Proposal Email,"},// Inquiry, Sale, Purchase, Invoice
  {name:"CustomerName",value:"CustomerName", page:"Submission Email,Purchase Order Email,Sale Order Email,Inquiry Email,Invoice Email,Proposal Email,"},// Inquiry, Sale, Purchase, Invoice
  {name:"DeliveryAddress",value:"DeliveryAddress", page:"Invoice Email,"},//Invoice
  {name:"DeliveryDate",value:"DeliveryDate", page:"Invoice Email,"},//Invoice
  {name:"DeliveryNotes",value:"DeliveryNotes", page:"Invoice Email,"},//Invoice
  {name:"DeliveryRef",value:"DeliveryRef", page:"Invoice Email,"},//Invoice
  {name:"ExpectedDeliveryDate",value:"ExpectedDeliveryDate", page:"Purchase Order Email,Sale Order Email,Inquiry Email,Invoice Email,"},// Inquiry, Sale, Purchase, Invoice
  {name:"InvoiceNo",value:"InvoiceNo", page:"Invoice Email,"},//Invoice
  {name:"PurchaseOrderNo",value:"PurchaseOrderNo", page:"Purchase Order Email,Invoice Email,"},//Purchase, Invoice
  {name:"ProjectName",value:"ProjectName", page:"Submission Email,Purchase Order Email,Sale Order Email,Inquiry Email,Invoice Email,Proposal Email,"},// Inquiry, Sale, Purchase, Invoice
  {name:"SaleOrderNo",value:"SaleOrderNo", page:"Purchase Order Email,Sale Order Email,Invoice Email,"},// Sale, Purchase, Invoice
  {name:"SiteLogo",value:"SiteLogo", page:"Submission Email,Proposal Email,Purchase Order Email,Sale Order Email,Invoice Email,Inquiry Email,"},// Sale, Purchase, Invoice
  {name:"ShipToAddress",value:"ShipToAddress", page:"Purchase Order Email,Sale Order Email,Inquiry Email,Invoice Email,"},// Inquiry, Sale, Purchase, Invoice
  {name:"ShipToCompanyName",value:"ShipToCompanyName", page:"Purchase Order Email,Sale Order Email,Invoice Email,"},// Sale, Purchase, Invoice
  {name:"SupplierName",value:"SupplierName", page:"Purchase Order Email,Sale Order Email,Invoice Email,Inquiry Email,"},// Sale, Purchase, Invoice
  {name:"Terms",value:"Terms", page:"Purchase Order Email,Sale Order Email,Invoice Email,"},//Sale, Purchase, Invoice
  {name:"ProposalId",value:"ProposalId", page:"Proposal Email,"},//Sale, Purchase, Invoice
  {name:"ProjectNo",value:"ProjectNo", page:"Submission Email,"},// Inquiry, Sale, Purchase, Invoice
  {name:"DrawingTable",value:"DrawingTable", page:"Submission Email,"},
]



interface Quote {
  id: number;
  quote: string;
  author: string;
}

export const quotesDB: Quote[] = [
  {
    id: 1,
    quote: 'Do what you can, with what you have, where you are.',
    author: 'Theodore Roosevelt',
  },
  {
    id: 2,
    quote: 'A man may die, nations may rise and fall, but an idea lives on.',
    author: 'John F. Kennedy',
  },
  {
    id: 3,
    quote: 'We cannot build our own future without helping others to build theirs.',
    author: 'Bill Clinton',
  },
  {
    id: 4,
    quote: 'Change will not come if we wait for some other person, or if we wait for some other time. We are the ones we\'ve been waiting for. We are the change that we seek.',
    author: 'Barack Obama',
  },
  {
    id: 5,
    quote: 'Nothing in this world can take the place of persistence.',
    author: 'Calvin Coolidge',
  },
  {
    id: 6,
    quote: 'The best way to predict your future is to create it.',
    author: 'Abraham Lincoln',
  },
  {
    id: 7,
    quote: 'Happiness is not in the mere possession of money; it lies in the joy of achievement, in the thrill of creative effort.',
    author: 'Franklin Delano Roosevelt',
  },
  {
    id: 8,
    quote: 'We have become not a melting pot but a beautiful mosaic. Different people, different beliefs, different yearnings, different hopes, different dreams.',
    author: 'Jimmy Carter',
  },
  {
    id: 9,
    quote: 'Perseverance and spirit have done wonders in all ages.',
    author: 'George Washington',
  },
  {
    id: 10,
    quote: 'Everything in life should be done with reflection.',
    author: 'John Adams',
  },
  {
    id: 11,
    quote: 'For we are given power not to advance our own purposes, nor to make a great show in the world, nor a name. There is but one just use of power, and it is to serve people.',
    author: 'George W. Bush',
  },
  {
    id: 12,
    quote: 'We are bound together by the most powerful of all ties, our fervent love for freedom and independence, which knows no homeland but the human heart.',
    author: 'Gerald Ford',
  },
  {
    id: 13,
    quote: 'The life of a republic lies certainly in the energy, virtue, and intelligence of its citizens.',
    author: 'Andrew Johnson',
  },
  {
    id: 14,
    quote: 'For history does not long entrust the care of freedom to the weak or the timid.',
    author: 'Dwight D. Eisenhower',
  },
  {
    id: 15,
    quote: 'You know, doing what is right is easy. The problem is knowing what is right.',
    author: 'Lyndon B. Johnson',
  },
  {
    id: 16,
    quote: 'Losing is never easy. Trust me, I know something about that. But if you have to lose, that\'s the way to do it: Fight with all you have. Give it your best shot. And win or lose, learn from it, and get on with life.',
    author: 'George H.W. Bush',
  },
  {
    id: 17,
    quote: 'A smooth sea never made a skilled sailor.',
    author: 'Franklin Delano Roosevelt',
  },
  {
    id: 18,
    quote: 'On matters of style, swim with the current, on matters of principle, stand like a rock.',
    author: 'Thomas Jefferson',
  },
  {
    id: 19,
    quote: 'There is no limit to the amount of good you can do if you don\'t care who gets the credit.',
    author: 'Ronald Reagan',
  },
  {
    id: 20,
    quote: 'Though our challenges are fearsome, so are our strengths. And Americans have ever been a restless, questing, hopeful people.',
    author: 'Bill Clinton',
  },
  {
    id: 21,
    quote: 'Knowledge will forever govern ignorance, and a people who mean to be their own governors, must arm themselves with the power knowledge gives.',
    author: 'James Madison',
  },
  {
    id: 22,
    quote: 'Words without actions are the assassins of idealism.',
    author: 'Herbert Hoover',
  },
  {
    id: 23,
    quote: 'We must support our rights or lose our character, and with it, perhaps, our liberties.',
    author: 'James Monroe',
  },
  {
    id: 24,
    quote: 'It’s important to make sure that we’re talking with each other in a way that heals, not in a way that wounds.',
    author: 'Barack Obama',
  },
  {
    id: 25,
    quote: 'Do your best, history will do the rest.',
    author: 'Harry S. Truman',
  },
  {
    id: 26,
    quote: 'The difference between a strong man and a weak one is that the former does not give up after a defeat.',
    author: 'Woodrow Wilson',
  },
  {
    id: 27,
    quote: 'If the problem is fear, the answer is knowledge.',
    author: 'Joe Biden',
  },
  {
    id: 28,
    quote: 'If you take no risks, you will suffer no defeats. But if you take no risks, you win no victories.',
    author: 'Richard Nixon',
  },
  {
    id: 29,
    quote: 'Strong hearts and helpful hands are needed, and, fortunately, we have them in every part of our beloved country.',
    author: 'William McKinley',
  },
  {
    id: 30,
    quote: 'I\'m a greater believer in luck, and I find the harder I work the more I have of it.',
    author: 'Thomas Jefferson',
  }
];  
